<!--  -->
<template>
  <div class="main">
       <el-radio-group v-model="roleId" style="height: 34px;margin:30px 0" class="cinput" @change='getTreeList'>
            <el-radio-button :label="item.id" v-for='(item,index) in roleList' :key='index'>{{item.name}}</el-radio-button>
          </el-radio-group>
        <!-- <el-tree
            :data="data"
            show-checkbox
            node-key="permissionId"
            :default-checked-keys="selectCheck"
            :props="defaultProps">
        </el-tree> -->
        <el-collapse v-model="activeNames">
          <el-collapse-item  :name="item.permissionId" v-for="item in data" :key='item.permissionId'>
            <el-checkbox v-model="item.isSelect">{{item.name}}</el-checkbox>
              <div style="margin-left:20px">
                <el-checkbox v-model="item2.isSelect" v-for="item2 in item.children" :key='item2.permissionId' @change="changeChecked($event,item2)">{{item2.name}}</el-checkbox>
              </div>
          </el-collapse-item>
        </el-collapse>
        <el-button type="primary" @click="save">保存</el-button>
  </div>
</template>

<script>
import {treeList,updateTree} from '../../apis/index'
export default {
  data () {
    return {
      activeNames:[],
        res2:[],
        roleList:[
          
        ],
        roleId:'2',
        selectCheck:[],
         data: [],
         defaultProps: {
          children: 'children',
          label: 'name'
        }
    }
  },
  
  created(){
       this.roleList=this.$store.state.roleList.concat({
            id:6,
            name:'助理'
          })
       this.getTreeList()
  },
  methods:{
    changeChecked(e,items){//选择子集反选父元素
      if(e){
        this.data.map(item=>{
          if(item.children){
            item.children.forEach(item2=>{
              if(item2.permissionId==items.permissionId){
                item.isSelect=true
              }
            })
          }
        })
      }
      
    },
     async getTreeList(){//获取权限树列表
        const {data}=await treeList({roleId:this.roleId})
        if(data.code==200){
            // this.data=data.data.children
              let res=data.data.children.map(item=>{
                item.isSelect==0?item.isSelect=false:item.isSelect=true
                return item
            })
           let activeNames=data.data.children.map(item=>{
                return item.permissionId
            })
            this.activeNames=activeNames
            console.log(activeNames)
            let res2=[]
            res.map(item=>{
                 if(item.children){
                    res2=item.children.map(item2=>{
                     item2.isSelect==0?item2.isSelect=false:item2.isSelect=true
                   return item2
               })
               }
            })
            this.data=res
            console.log(res) 
        }
     },
    save(){//保存
     let data=this.data
       let res=data.map(item=>{
                item.isSelect==false?item.isSelect=0:item.isSelect=1
                return item
            })
            let res2=[]
            res.map(item=>{
                 if(item.children){
                    res2=item.children.map(item2=>{
                     item2.isSelect==false?item2.isSelect=0:item2.isSelect=1
                   return item2
               })
               }
            })
      const params={
        children:res,
        isSelect:0,
        permissionId:0,
        roleId:this.roleId,
        rolePermissionId:0
      }
      updateTree(params).then(res=>{
        if(res.data.code==200){
                this.$message.success(res.data.msg);
                this.getTreeList()
           }else{
               this.$message.error(res.data.msg);
           }  
        }
     )
     }
  }
}
</script>

<style lang='less' scoped>
    .main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
  margin:30px 0
}
</style>
